import { default as aboutZlXDm5pKvsMeta } from "/tmp/build_213e7411/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/about.vue?macro=true";
import { default as eventsTT8Ktwdbc5Meta } from "/tmp/build_213e7411/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/events.vue?macro=true";
import { default as _91media_id_93G8BaNgiWgrMeta } from "/tmp/build_213e7411/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-media/[media_id].vue?macro=true";
import { default as free_45sampleWB4DFCQ3MJMeta } from "/tmp/build_213e7411/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-sample.vue?macro=true";
import { default as included_45productsyf7wrl1yjDMeta } from "/tmp/build_213e7411/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/included-products.vue?macro=true";
import { default as _91media_id_93aspxOEOmElMeta } from "/tmp/build_213e7411/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue?macro=true";
import { default as viewGsYqo7UFNeMeta } from "/tmp/build_213e7411/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/view.vue?macro=true";
import { default as index9tZebDNpjOMeta } from "/tmp/build_213e7411/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/index.vue?macro=true";
import { default as _91media_id_937ieSL1HaliMeta } from "/tmp/build_213e7411/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/media/[media_id].vue?macro=true";
import { default as indexZGazMA8scIMeta } from "/tmp/build_213e7411/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/index.vue?macro=true";
import { default as _91media_id_93q8C775kKPDMeta } from "/tmp/build_213e7411/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue?macro=true";
import { default as indexUgUag9BekZMeta } from "/tmp/build_213e7411/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/index.vue?macro=true";
import { default as private_45sessionsXawG9fr9h3Meta } from "/tmp/build_213e7411/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/private-sessions.vue?macro=true";
import { default as recordings6TrY9Itl41Meta } from "/tmp/build_213e7411/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/recordings.vue?macro=true";
import { default as reviewsPz9rWEa7taMeta } from "/tmp/build_213e7411/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/reviews.vue?macro=true";
import { default as _91product_id_93FcatA4T0b6Meta } from "/tmp/build_213e7411/pages/[studio_url]/bundle/[bundle_id]/product/[product_id].vue?macro=true";
import { default as index7Q259JDsM5Meta } from "/tmp/build_213e7411/pages/[studio_url]/buy/product/[product_id]/index.vue?macro=true";
import { default as confirmJVqsRdvCqxMeta } from "/tmp/build_213e7411/pages/[studio_url]/buy/product/[product_id]/paypal/confirm.vue?macro=true";
import { default as confirmGVXKswUxhgMeta } from "/tmp/build_213e7411/pages/[studio_url]/buy/product/[product_id]/stripe/confirm.vue?macro=true";
import { default as calendargsMlAMcrGYMeta } from "/tmp/build_213e7411/pages/[studio_url]/calendar.vue?macro=true";
import { default as chatXSgiZUiFUQMeta } from "/tmp/build_213e7411/pages/[studio_url]/chat.vue?macro=true";
import { default as about5xlOB1ljC6Meta } from "/tmp/build_213e7411/pages/[studio_url]/community-groups/[group_id]/about.vue?macro=true";
import { default as feedEmh2KD9xRtMeta } from "/tmp/build_213e7411/pages/[studio_url]/community-groups/[group_id]/feed.vue?macro=true";
import { default as membersYgziG5lmZwMeta } from "/tmp/build_213e7411/pages/[studio_url]/community-groups/[group_id]/members.vue?macro=true";
import { default as _91group_id_93sDdnyHzCc2Meta } from "/tmp/build_213e7411/pages/[studio_url]/community-groups/[group_id].vue?macro=true";
import { default as community_45profile_45edit12gTFCSQiMMeta } from "/tmp/build_213e7411/pages/[studio_url]/community-profile-edit.vue?macro=true";
import { default as _91id_9348d2w8ZNn5Meta } from "/tmp/build_213e7411/pages/[studio_url]/community-profile/[id].vue?macro=true";
import { default as communityg8zvGy1DFEMeta } from "/tmp/build_213e7411/pages/[studio_url]/community.vue?macro=true";
import { default as _91uuid_93bjV7fKWtwwMeta } from "/tmp/build_213e7411/pages/[studio_url]/complete-registration/[uuid].vue?macro=true";
import { default as _91unique_id_93smS3UGNTgrMeta } from "/tmp/build_213e7411/pages/[studio_url]/contracts/[unique_id].vue?macro=true";
import { default as dashboardo3xgz2cqANMeta } from "/tmp/build_213e7411/pages/[studio_url]/dashboard.vue?macro=true";
import { default as index2OCv5S1eNsMeta } from "/tmp/build_213e7411/pages/[studio_url]/event/details/[event_id]/index.vue?macro=true";
import { default as checkoutYmsIWyfSV3Meta } from "/tmp/build_213e7411/pages/[studio_url]/event/details/[event_id]/product/[product_id]/checkout.vue?macro=true";
import { default as select_45product3h5epfxk7OMeta } from "/tmp/build_213e7411/pages/[studio_url]/event/details/[event_id]/select-product.vue?macro=true";
import { default as eventsxPyy5CyfteMeta } from "/tmp/build_213e7411/pages/[studio_url]/events.vue?macro=true";
import { default as _91uuid_938qixGU3Ck1Meta } from "/tmp/build_213e7411/pages/[studio_url]/gifts/[uuid].vue?macro=true";
import { default as indexuEtsxbkIE0Meta } from "/tmp/build_213e7411/pages/[studio_url]/gifts/index.vue?macro=true";
import { default as _91invitation_id_93dqjXufmhdRMeta } from "/tmp/build_213e7411/pages/[studio_url]/gifts/invitation/[invitation_id].vue?macro=true";
import { default as _91product_id_93HpOAmz8mntMeta } from "/tmp/build_213e7411/pages/[studio_url]/gifts/product/[product_id].vue?macro=true";
import { default as indexJSL1rFOyrsMeta } from "/tmp/build_213e7411/pages/[studio_url]/index.vue?macro=true";
import { default as _91invitation_unique_id_93wuULmhQ88vMeta } from "/tmp/build_213e7411/pages/[studio_url]/invitation/[invitation_unique_id].vue?macro=true";
import { default as joinze2A2UpDFGMeta } from "/tmp/build_213e7411/pages/[studio_url]/join.vue?macro=true";
import { default as loginsmq2LpZLZVMeta } from "/tmp/build_213e7411/pages/[studio_url]/login.vue?macro=true";
import { default as logoutO9rCvIgWrLMeta } from "/tmp/build_213e7411/pages/[studio_url]/logout.vue?macro=true";
import { default as _91meeting_type_slug_93mWOO6ByLjAMeta } from "/tmp/build_213e7411/pages/[studio_url]/meet/[meeting_type_slug].vue?macro=true";
import { default as _91meeting_unique_id_93YaKCGAQy2WMeta } from "/tmp/build_213e7411/pages/[studio_url]/meet/[meeting_unique_id].vue?macro=true";
import { default as _91meeting_unique_id_934yO09D7VS3Meta } from "/tmp/build_213e7411/pages/[studio_url]/meeting/[meeting_unique_id].vue?macro=true";
import { default as my_45favoritesIT2QGR9aMRMeta } from "/tmp/build_213e7411/pages/[studio_url]/my-favorites.vue?macro=true";
import { default as my_45studiosxl1hIvuXDfMeta } from "/tmp/build_213e7411/pages/[studio_url]/my-studios.vue?macro=true";
import { default as optin3spJZFtDQgMeta } from "/tmp/build_213e7411/pages/[studio_url]/optin.vue?macro=true";
import { default as _91episode_id_939FlThrNGlvMeta } from "/tmp/build_213e7411/pages/[studio_url]/podcasts/[podcast_id]/episode/[episode_id].vue?macro=true";
import { default as index9kkp8lksafMeta } from "/tmp/build_213e7411/pages/[studio_url]/podcasts/[podcast_id]/index.vue?macro=true";
import { default as indexozVHmGeUGMMeta } from "/tmp/build_213e7411/pages/[studio_url]/podcasts/index.vue?macro=true";
import { default as about3hXf3Gr9AuMeta } from "/tmp/build_213e7411/pages/[studio_url]/product/[product_id]/about.vue?macro=true";
import { default as eventsayewGGPTVIMeta } from "/tmp/build_213e7411/pages/[studio_url]/product/[product_id]/events.vue?macro=true";
import { default as _91media_id_93rJxnN8DdoiMeta } from "/tmp/build_213e7411/pages/[studio_url]/product/[product_id]/free-media/[media_id].vue?macro=true";
import { default as free_45sampleueRoaT2dcaMeta } from "/tmp/build_213e7411/pages/[studio_url]/product/[product_id]/free-sample.vue?macro=true";
import { default as included_45productsiu4eAYvTloMeta } from "/tmp/build_213e7411/pages/[studio_url]/product/[product_id]/included-products.vue?macro=true";
import { default as _91media_id_93ByVGmLWM5hMeta } from "/tmp/build_213e7411/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue?macro=true";
import { default as viewvJLYK8IIJaMeta } from "/tmp/build_213e7411/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/view.vue?macro=true";
import { default as indexX96fDBmnsqMeta } from "/tmp/build_213e7411/pages/[studio_url]/product/[product_id]/lessons/index.vue?macro=true";
import { default as _91media_id_93FZe6bmOcdzMeta } from "/tmp/build_213e7411/pages/[studio_url]/product/[product_id]/media/[media_id].vue?macro=true";
import { default as index2ByNFgwVZvMeta } from "/tmp/build_213e7411/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/index.vue?macro=true";
import { default as _91media_id_93b4bFK6ZQiTMeta } from "/tmp/build_213e7411/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue?macro=true";
import { default as indexn7e2FabgbvMeta } from "/tmp/build_213e7411/pages/[studio_url]/product/[product_id]/playlists/index.vue?macro=true";
import { default as private_45sessionsc13VWdfV1BMeta } from "/tmp/build_213e7411/pages/[studio_url]/product/[product_id]/private-sessions.vue?macro=true";
import { default as recordingsTS4eJUqgTbMeta } from "/tmp/build_213e7411/pages/[studio_url]/product/[product_id]/recordings.vue?macro=true";
import { default as reviewsDbZZkljlvZMeta } from "/tmp/build_213e7411/pages/[studio_url]/product/[product_id]/reviews.vue?macro=true";
import { default as _91product_id_93ZaqXtuSNXZMeta } from "/tmp/build_213e7411/pages/[studio_url]/product/[product_id].vue?macro=true";
import { default as indexsJrPy0WBebMeta } from "/tmp/build_213e7411/pages/[studio_url]/product/index.vue?macro=true";
import { default as indexxxL0vhgerhMeta } from "/tmp/build_213e7411/pages/[studio_url]/profile/index.vue?macro=true";
import { default as purchasesTq4XDWPhKCMeta } from "/tmp/build_213e7411/pages/[studio_url]/profile/purchases.vue?macro=true";
import { default as redemptionsAMptQrGcUEMeta } from "/tmp/build_213e7411/pages/[studio_url]/profile/redemptions.vue?macro=true";
import { default as _91quiz_id_93Ok1U4nfTLSMeta } from "/tmp/build_213e7411/pages/[studio_url]/quiz/[quiz_id].vue?macro=true";
import { default as _91token_93ZIxr7yh9T7Meta } from "/tmp/build_213e7411/pages/[studio_url]/reset-password/[uid]/[token].vue?macro=true";
import { default as resetp9Jfo31foeMeta } from "/tmp/build_213e7411/pages/[studio_url]/reset.vue?macro=true";
import { default as shopGyDZ1Rvn0nMeta } from "/tmp/build_213e7411/pages/[studio_url]/shop.vue?macro=true";
import { default as signupNZMMIIOocMMeta } from "/tmp/build_213e7411/pages/[studio_url]/signup.vue?macro=true";
import { default as liveAnE28KXQkvMeta } from "/tmp/build_213e7411/pages/[studio_url]/streaming/[event_id]/live.vue?macro=true";
import { default as tipsXq0M6uoTrpMeta } from "/tmp/build_213e7411/pages/[studio_url]/tips.vue?macro=true";
import { default as _91uuid_93PcTEv4dZhwMeta } from "/tmp/build_213e7411/pages/[studio_url]/waiver/[uuid].vue?macro=true";
import { default as compactqSp3RKcAnEMeta } from "/tmp/build_213e7411/pages/[studio_url]/widgets/calendars/compact.vue?macro=true";
import { default as fulliOI3YsDzzrMeta } from "/tmp/build_213e7411/pages/[studio_url]/widgets/calendars/full.vue?macro=true";
export default [
  {
    name: _91product_id_93FcatA4T0b6Meta?.name ?? "bundle-bundle_id-product-product_id",
    path: _91product_id_93FcatA4T0b6Meta?.path ?? "/bundle/:bundle_id()/product/:product_id()",
    meta: _91product_id_93FcatA4T0b6Meta || {},
    alias: _91product_id_93FcatA4T0b6Meta?.alias || [],
    redirect: _91product_id_93FcatA4T0b6Meta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/bundle/[bundle_id]/product/[product_id].vue").then(m => m.default || m),
    children: [
  {
    name: aboutZlXDm5pKvsMeta?.name ?? "bundle-bundle_id-product-product_id-about",
    path: aboutZlXDm5pKvsMeta?.path ?? "about",
    meta: aboutZlXDm5pKvsMeta || {},
    alias: aboutZlXDm5pKvsMeta?.alias || [],
    redirect: aboutZlXDm5pKvsMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/about.vue").then(m => m.default || m)
  },
  {
    name: eventsTT8Ktwdbc5Meta?.name ?? "bundle-bundle_id-product-product_id-events",
    path: eventsTT8Ktwdbc5Meta?.path ?? "events",
    meta: eventsTT8Ktwdbc5Meta || {},
    alias: eventsTT8Ktwdbc5Meta?.alias || [],
    redirect: eventsTT8Ktwdbc5Meta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/events.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93G8BaNgiWgrMeta?.name ?? "bundle-bundle_id-product-product_id-free-media-media_id",
    path: _91media_id_93G8BaNgiWgrMeta?.path ?? "free-media/:media_id()",
    meta: _91media_id_93G8BaNgiWgrMeta || {},
    alias: _91media_id_93G8BaNgiWgrMeta?.alias || [],
    redirect: _91media_id_93G8BaNgiWgrMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: free_45sampleWB4DFCQ3MJMeta?.name ?? "bundle-bundle_id-product-product_id-free-sample",
    path: free_45sampleWB4DFCQ3MJMeta?.path ?? "free-sample",
    meta: free_45sampleWB4DFCQ3MJMeta || {},
    alias: free_45sampleWB4DFCQ3MJMeta?.alias || [],
    redirect: free_45sampleWB4DFCQ3MJMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/free-sample.vue").then(m => m.default || m)
  },
  {
    name: included_45productsyf7wrl1yjDMeta?.name ?? "bundle-bundle_id-product-product_id-included-products",
    path: included_45productsyf7wrl1yjDMeta?.path ?? "included-products",
    meta: included_45productsyf7wrl1yjDMeta || {},
    alias: included_45productsyf7wrl1yjDMeta?.alias || [],
    redirect: included_45productsyf7wrl1yjDMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/included-products.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93aspxOEOmElMeta?.name ?? "bundle-bundle_id-product-product_id-lessons-lesson_id-play-media_id",
    path: _91media_id_93aspxOEOmElMeta?.path ?? "lessons/:lesson_id()/play/:media_id()",
    meta: _91media_id_93aspxOEOmElMeta || {},
    alias: _91media_id_93aspxOEOmElMeta?.alias || [],
    redirect: _91media_id_93aspxOEOmElMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue").then(m => m.default || m)
  },
  {
    name: viewGsYqo7UFNeMeta?.name ?? "bundle-bundle_id-product-product_id-lessons-lesson_id-view",
    path: viewGsYqo7UFNeMeta?.path ?? "lessons/:lesson_id()/view",
    meta: viewGsYqo7UFNeMeta || {},
    alias: viewGsYqo7UFNeMeta?.alias || [],
    redirect: viewGsYqo7UFNeMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/[lesson_id]/view.vue").then(m => m.default || m)
  },
  {
    name: index9tZebDNpjOMeta?.name ?? "bundle-bundle_id-product-product_id-lessons",
    path: index9tZebDNpjOMeta?.path ?? "lessons",
    meta: index9tZebDNpjOMeta || {},
    alias: index9tZebDNpjOMeta?.alias || [],
    redirect: index9tZebDNpjOMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/lessons/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_937ieSL1HaliMeta?.name ?? "bundle-bundle_id-product-product_id-media-media_id",
    path: _91media_id_937ieSL1HaliMeta?.path ?? "media/:media_id()",
    meta: _91media_id_937ieSL1HaliMeta || {},
    alias: _91media_id_937ieSL1HaliMeta?.alias || [],
    redirect: _91media_id_937ieSL1HaliMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: indexZGazMA8scIMeta?.name ?? "bundle-bundle_id-product-product_id-playlists-playlist_id",
    path: indexZGazMA8scIMeta?.path ?? "playlists/:playlist_id()",
    meta: indexZGazMA8scIMeta || {},
    alias: indexZGazMA8scIMeta?.alias || [],
    redirect: indexZGazMA8scIMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93q8C775kKPDMeta?.name ?? "bundle-bundle_id-product-product_id-playlists-playlist_id-media-media_id",
    path: _91media_id_93q8C775kKPDMeta?.path ?? "playlists/:playlist_id()/media/:media_id()",
    meta: _91media_id_93q8C775kKPDMeta || {},
    alias: _91media_id_93q8C775kKPDMeta?.alias || [],
    redirect: _91media_id_93q8C775kKPDMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: indexUgUag9BekZMeta?.name ?? "bundle-bundle_id-product-product_id-playlists",
    path: indexUgUag9BekZMeta?.path ?? "playlists",
    meta: indexUgUag9BekZMeta || {},
    alias: indexUgUag9BekZMeta?.alias || [],
    redirect: indexUgUag9BekZMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/playlists/index.vue").then(m => m.default || m)
  },
  {
    name: private_45sessionsXawG9fr9h3Meta?.name ?? "bundle-bundle_id-product-product_id-private-sessions",
    path: private_45sessionsXawG9fr9h3Meta?.path ?? "private-sessions",
    meta: private_45sessionsXawG9fr9h3Meta || {},
    alias: private_45sessionsXawG9fr9h3Meta?.alias || [],
    redirect: private_45sessionsXawG9fr9h3Meta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/private-sessions.vue").then(m => m.default || m)
  },
  {
    name: recordings6TrY9Itl41Meta?.name ?? "bundle-bundle_id-product-product_id-recordings",
    path: recordings6TrY9Itl41Meta?.path ?? "recordings",
    meta: recordings6TrY9Itl41Meta || {},
    alias: recordings6TrY9Itl41Meta?.alias || [],
    redirect: recordings6TrY9Itl41Meta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/recordings.vue").then(m => m.default || m)
  },
  {
    name: reviewsPz9rWEa7taMeta?.name ?? "bundle-bundle_id-product-product_id-reviews",
    path: reviewsPz9rWEa7taMeta?.path ?? "reviews",
    meta: reviewsPz9rWEa7taMeta || {},
    alias: reviewsPz9rWEa7taMeta?.alias || [],
    redirect: reviewsPz9rWEa7taMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/bundle/[bundle_id]/product/[product_id]/reviews.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index7Q259JDsM5Meta?.name ?? "buy-product-product_id",
    path: index7Q259JDsM5Meta?.path ?? "/buy/product/:product_id()",
    meta: index7Q259JDsM5Meta || {},
    alias: index7Q259JDsM5Meta?.alias || [],
    redirect: index7Q259JDsM5Meta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/buy/product/[product_id]/index.vue").then(m => m.default || m)
  },
  {
    name: confirmJVqsRdvCqxMeta?.name ?? "buy-product-product_id-paypal-confirm",
    path: confirmJVqsRdvCqxMeta?.path ?? "/buy/product/:product_id()/paypal/confirm",
    meta: confirmJVqsRdvCqxMeta || {},
    alias: confirmJVqsRdvCqxMeta?.alias || [],
    redirect: confirmJVqsRdvCqxMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/buy/product/[product_id]/paypal/confirm.vue").then(m => m.default || m)
  },
  {
    name: confirmGVXKswUxhgMeta?.name ?? "buy-product-product_id-stripe-confirm",
    path: confirmGVXKswUxhgMeta?.path ?? "/buy/product/:product_id()/stripe/confirm",
    meta: confirmGVXKswUxhgMeta || {},
    alias: confirmGVXKswUxhgMeta?.alias || [],
    redirect: confirmGVXKswUxhgMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/buy/product/[product_id]/stripe/confirm.vue").then(m => m.default || m)
  },
  {
    name: calendargsMlAMcrGYMeta?.name ?? "calendar",
    path: calendargsMlAMcrGYMeta?.path ?? "/calendar",
    meta: calendargsMlAMcrGYMeta || {},
    alias: calendargsMlAMcrGYMeta?.alias || [],
    redirect: calendargsMlAMcrGYMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/calendar.vue").then(m => m.default || m)
  },
  {
    name: chatXSgiZUiFUQMeta?.name ?? "chat",
    path: chatXSgiZUiFUQMeta?.path ?? "/chat",
    meta: chatXSgiZUiFUQMeta || {},
    alias: chatXSgiZUiFUQMeta?.alias || [],
    redirect: chatXSgiZUiFUQMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/chat.vue").then(m => m.default || m)
  },
  {
    name: _91group_id_93sDdnyHzCc2Meta?.name ?? "community-groups-group_id",
    path: _91group_id_93sDdnyHzCc2Meta?.path ?? "/community-groups/:group_id()",
    meta: _91group_id_93sDdnyHzCc2Meta || {},
    alias: _91group_id_93sDdnyHzCc2Meta?.alias || [],
    redirect: _91group_id_93sDdnyHzCc2Meta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/community-groups/[group_id].vue").then(m => m.default || m),
    children: [
  {
    name: about5xlOB1ljC6Meta?.name ?? "community-groups-group_id-about",
    path: about5xlOB1ljC6Meta?.path ?? "about",
    meta: about5xlOB1ljC6Meta || {},
    alias: about5xlOB1ljC6Meta?.alias || [],
    redirect: about5xlOB1ljC6Meta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/community-groups/[group_id]/about.vue").then(m => m.default || m)
  },
  {
    name: feedEmh2KD9xRtMeta?.name ?? "community-groups-group_id-feed",
    path: feedEmh2KD9xRtMeta?.path ?? "feed",
    meta: feedEmh2KD9xRtMeta || {},
    alias: feedEmh2KD9xRtMeta?.alias || [],
    redirect: feedEmh2KD9xRtMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/community-groups/[group_id]/feed.vue").then(m => m.default || m)
  },
  {
    name: membersYgziG5lmZwMeta?.name ?? "community-groups-group_id-members",
    path: membersYgziG5lmZwMeta?.path ?? "members",
    meta: membersYgziG5lmZwMeta || {},
    alias: membersYgziG5lmZwMeta?.alias || [],
    redirect: membersYgziG5lmZwMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/community-groups/[group_id]/members.vue").then(m => m.default || m)
  }
]
  },
  {
    name: community_45profile_45edit12gTFCSQiMMeta?.name ?? "community-profile-edit",
    path: community_45profile_45edit12gTFCSQiMMeta?.path ?? "/community-profile-edit",
    meta: community_45profile_45edit12gTFCSQiMMeta || {},
    alias: community_45profile_45edit12gTFCSQiMMeta?.alias || [],
    redirect: community_45profile_45edit12gTFCSQiMMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/community-profile-edit.vue").then(m => m.default || m)
  },
  {
    name: _91id_9348d2w8ZNn5Meta?.name ?? "community-profile-id",
    path: _91id_9348d2w8ZNn5Meta?.path ?? "/community-profile/:id()",
    meta: _91id_9348d2w8ZNn5Meta || {},
    alias: _91id_9348d2w8ZNn5Meta?.alias || [],
    redirect: _91id_9348d2w8ZNn5Meta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/community-profile/[id].vue").then(m => m.default || m)
  },
  {
    name: communityg8zvGy1DFEMeta?.name ?? "community",
    path: communityg8zvGy1DFEMeta?.path ?? "/community",
    meta: communityg8zvGy1DFEMeta || {},
    alias: communityg8zvGy1DFEMeta?.alias || [],
    redirect: communityg8zvGy1DFEMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/community.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93bjV7fKWtwwMeta?.name ?? "complete-registration-uuid",
    path: _91uuid_93bjV7fKWtwwMeta?.path ?? "/complete-registration/:uuid()",
    meta: _91uuid_93bjV7fKWtwwMeta || {},
    alias: _91uuid_93bjV7fKWtwwMeta?.alias || [],
    redirect: _91uuid_93bjV7fKWtwwMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/complete-registration/[uuid].vue").then(m => m.default || m)
  },
  {
    name: _91unique_id_93smS3UGNTgrMeta?.name ?? "contracts-unique_id",
    path: _91unique_id_93smS3UGNTgrMeta?.path ?? "/contracts/:unique_id()",
    meta: _91unique_id_93smS3UGNTgrMeta || {},
    alias: _91unique_id_93smS3UGNTgrMeta?.alias || [],
    redirect: _91unique_id_93smS3UGNTgrMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/contracts/[unique_id].vue").then(m => m.default || m)
  },
  {
    name: dashboardo3xgz2cqANMeta?.name ?? "dashboard",
    path: dashboardo3xgz2cqANMeta?.path ?? "/dashboard",
    meta: dashboardo3xgz2cqANMeta || {},
    alias: dashboardo3xgz2cqANMeta?.alias || [],
    redirect: dashboardo3xgz2cqANMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: index2OCv5S1eNsMeta?.name ?? "event-details-event_id",
    path: index2OCv5S1eNsMeta?.path ?? "/event/details/:event_id()",
    meta: index2OCv5S1eNsMeta || {},
    alias: index2OCv5S1eNsMeta?.alias || [],
    redirect: index2OCv5S1eNsMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/event/details/[event_id]/index.vue").then(m => m.default || m)
  },
  {
    name: checkoutYmsIWyfSV3Meta?.name ?? "event-details-event_id-product-product_id-checkout",
    path: checkoutYmsIWyfSV3Meta?.path ?? "/event/details/:event_id()/product/:product_id()/checkout",
    meta: checkoutYmsIWyfSV3Meta || {},
    alias: checkoutYmsIWyfSV3Meta?.alias || [],
    redirect: checkoutYmsIWyfSV3Meta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/event/details/[event_id]/product/[product_id]/checkout.vue").then(m => m.default || m)
  },
  {
    name: select_45product3h5epfxk7OMeta?.name ?? "event-details-event_id-select-product",
    path: select_45product3h5epfxk7OMeta?.path ?? "/event/details/:event_id()/select-product",
    meta: select_45product3h5epfxk7OMeta || {},
    alias: select_45product3h5epfxk7OMeta?.alias || [],
    redirect: select_45product3h5epfxk7OMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/event/details/[event_id]/select-product.vue").then(m => m.default || m)
  },
  {
    name: eventsxPyy5CyfteMeta?.name ?? "events",
    path: eventsxPyy5CyfteMeta?.path ?? "/events",
    meta: eventsxPyy5CyfteMeta || {},
    alias: eventsxPyy5CyfteMeta?.alias || [],
    redirect: eventsxPyy5CyfteMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/events.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_938qixGU3Ck1Meta?.name ?? "gifts-uuid",
    path: _91uuid_938qixGU3Ck1Meta?.path ?? "/gifts/:uuid()",
    meta: _91uuid_938qixGU3Ck1Meta || {},
    alias: _91uuid_938qixGU3Ck1Meta?.alias || [],
    redirect: _91uuid_938qixGU3Ck1Meta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/gifts/[uuid].vue").then(m => m.default || m)
  },
  {
    name: indexuEtsxbkIE0Meta?.name ?? "gifts",
    path: indexuEtsxbkIE0Meta?.path ?? "/gifts",
    meta: indexuEtsxbkIE0Meta || {},
    alias: indexuEtsxbkIE0Meta?.alias || [],
    redirect: indexuEtsxbkIE0Meta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/gifts/index.vue").then(m => m.default || m)
  },
  {
    name: _91invitation_id_93dqjXufmhdRMeta?.name ?? "gifts-invitation-invitation_id",
    path: _91invitation_id_93dqjXufmhdRMeta?.path ?? "/gifts/invitation/:invitation_id()",
    meta: _91invitation_id_93dqjXufmhdRMeta || {},
    alias: _91invitation_id_93dqjXufmhdRMeta?.alias || [],
    redirect: _91invitation_id_93dqjXufmhdRMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/gifts/invitation/[invitation_id].vue").then(m => m.default || m)
  },
  {
    name: _91product_id_93HpOAmz8mntMeta?.name ?? "gifts-product-product_id",
    path: _91product_id_93HpOAmz8mntMeta?.path ?? "/gifts/product/:product_id()",
    meta: _91product_id_93HpOAmz8mntMeta || {},
    alias: _91product_id_93HpOAmz8mntMeta?.alias || [],
    redirect: _91product_id_93HpOAmz8mntMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/gifts/product/[product_id].vue").then(m => m.default || m)
  },
  {
    name: indexJSL1rFOyrsMeta?.name ?? "index",
    path: indexJSL1rFOyrsMeta?.path ?? "/",
    meta: indexJSL1rFOyrsMeta || {},
    alias: indexJSL1rFOyrsMeta?.alias || [],
    redirect: indexJSL1rFOyrsMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/index.vue").then(m => m.default || m)
  },
  {
    name: _91invitation_unique_id_93wuULmhQ88vMeta?.name ?? "invitation-invitation_unique_id",
    path: _91invitation_unique_id_93wuULmhQ88vMeta?.path ?? "/invitation/:invitation_unique_id()",
    meta: _91invitation_unique_id_93wuULmhQ88vMeta || {},
    alias: _91invitation_unique_id_93wuULmhQ88vMeta?.alias || [],
    redirect: _91invitation_unique_id_93wuULmhQ88vMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/invitation/[invitation_unique_id].vue").then(m => m.default || m)
  },
  {
    name: joinze2A2UpDFGMeta?.name ?? "join",
    path: joinze2A2UpDFGMeta?.path ?? "/join",
    meta: joinze2A2UpDFGMeta || {},
    alias: joinze2A2UpDFGMeta?.alias || [],
    redirect: joinze2A2UpDFGMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/join.vue").then(m => m.default || m)
  },
  {
    name: loginsmq2LpZLZVMeta?.name ?? "login",
    path: loginsmq2LpZLZVMeta?.path ?? "/login",
    meta: loginsmq2LpZLZVMeta || {},
    alias: loginsmq2LpZLZVMeta?.alias || [],
    redirect: loginsmq2LpZLZVMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/login.vue").then(m => m.default || m)
  },
  {
    name: logoutO9rCvIgWrLMeta?.name ?? "logout",
    path: logoutO9rCvIgWrLMeta?.path ?? "/logout",
    meta: logoutO9rCvIgWrLMeta || {},
    alias: logoutO9rCvIgWrLMeta?.alias || [],
    redirect: logoutO9rCvIgWrLMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/logout.vue").then(m => m.default || m)
  },
  {
    name: _91meeting_type_slug_93mWOO6ByLjAMeta?.name ?? "meet-meeting_type_slug",
    path: _91meeting_type_slug_93mWOO6ByLjAMeta?.path ?? "/meet/:meeting_type_slug()",
    meta: _91meeting_type_slug_93mWOO6ByLjAMeta || {},
    alias: _91meeting_type_slug_93mWOO6ByLjAMeta?.alias || [],
    redirect: _91meeting_type_slug_93mWOO6ByLjAMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/meet/[meeting_type_slug].vue").then(m => m.default || m)
  },
  {
    name: _91meeting_unique_id_93YaKCGAQy2WMeta?.name ?? "meet-meeting_unique_id",
    path: _91meeting_unique_id_93YaKCGAQy2WMeta?.path ?? "/meet/:meeting_unique_id()",
    meta: _91meeting_unique_id_93YaKCGAQy2WMeta || {},
    alias: _91meeting_unique_id_93YaKCGAQy2WMeta?.alias || [],
    redirect: _91meeting_unique_id_93YaKCGAQy2WMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/meet/[meeting_unique_id].vue").then(m => m.default || m)
  },
  {
    name: _91meeting_unique_id_934yO09D7VS3Meta?.name ?? "meeting-meeting_unique_id",
    path: _91meeting_unique_id_934yO09D7VS3Meta?.path ?? "/meeting/:meeting_unique_id()",
    meta: _91meeting_unique_id_934yO09D7VS3Meta || {},
    alias: _91meeting_unique_id_934yO09D7VS3Meta?.alias || [],
    redirect: _91meeting_unique_id_934yO09D7VS3Meta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/meeting/[meeting_unique_id].vue").then(m => m.default || m)
  },
  {
    name: my_45favoritesIT2QGR9aMRMeta?.name ?? "my-favorites",
    path: my_45favoritesIT2QGR9aMRMeta?.path ?? "/my-favorites",
    meta: my_45favoritesIT2QGR9aMRMeta || {},
    alias: my_45favoritesIT2QGR9aMRMeta?.alias || [],
    redirect: my_45favoritesIT2QGR9aMRMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/my-favorites.vue").then(m => m.default || m)
  },
  {
    name: my_45studiosxl1hIvuXDfMeta?.name ?? "my-studios",
    path: my_45studiosxl1hIvuXDfMeta?.path ?? "/my-studios",
    meta: my_45studiosxl1hIvuXDfMeta || {},
    alias: my_45studiosxl1hIvuXDfMeta?.alias || [],
    redirect: my_45studiosxl1hIvuXDfMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/my-studios.vue").then(m => m.default || m)
  },
  {
    name: optin3spJZFtDQgMeta?.name ?? "optin",
    path: optin3spJZFtDQgMeta?.path ?? "/optin",
    meta: optin3spJZFtDQgMeta || {},
    alias: optin3spJZFtDQgMeta?.alias || [],
    redirect: optin3spJZFtDQgMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/optin.vue").then(m => m.default || m)
  },
  {
    name: _91episode_id_939FlThrNGlvMeta?.name ?? "podcasts-podcast_id-episode-episode_id",
    path: _91episode_id_939FlThrNGlvMeta?.path ?? "/podcasts/:podcast_id()/episode/:episode_id()",
    meta: _91episode_id_939FlThrNGlvMeta || {},
    alias: _91episode_id_939FlThrNGlvMeta?.alias || [],
    redirect: _91episode_id_939FlThrNGlvMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/podcasts/[podcast_id]/episode/[episode_id].vue").then(m => m.default || m)
  },
  {
    name: index9kkp8lksafMeta?.name ?? "podcasts-podcast_id",
    path: index9kkp8lksafMeta?.path ?? "/podcasts/:podcast_id()",
    meta: index9kkp8lksafMeta || {},
    alias: index9kkp8lksafMeta?.alias || [],
    redirect: index9kkp8lksafMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/podcasts/[podcast_id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexozVHmGeUGMMeta?.name ?? "podcasts",
    path: indexozVHmGeUGMMeta?.path ?? "/podcasts",
    meta: indexozVHmGeUGMMeta || {},
    alias: indexozVHmGeUGMMeta?.alias || [],
    redirect: indexozVHmGeUGMMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/podcasts/index.vue").then(m => m.default || m)
  },
  {
    name: _91product_id_93ZaqXtuSNXZMeta?.name ?? "product-product_id",
    path: _91product_id_93ZaqXtuSNXZMeta?.path ?? "/product/:product_id()",
    meta: _91product_id_93ZaqXtuSNXZMeta || {},
    alias: _91product_id_93ZaqXtuSNXZMeta?.alias || [],
    redirect: _91product_id_93ZaqXtuSNXZMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/product/[product_id].vue").then(m => m.default || m),
    children: [
  {
    name: about3hXf3Gr9AuMeta?.name ?? "product-product_id-about",
    path: about3hXf3Gr9AuMeta?.path ?? "about",
    meta: about3hXf3Gr9AuMeta || {},
    alias: about3hXf3Gr9AuMeta?.alias || [],
    redirect: about3hXf3Gr9AuMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/product/[product_id]/about.vue").then(m => m.default || m)
  },
  {
    name: eventsayewGGPTVIMeta?.name ?? "product-product_id-events",
    path: eventsayewGGPTVIMeta?.path ?? "events",
    meta: eventsayewGGPTVIMeta || {},
    alias: eventsayewGGPTVIMeta?.alias || [],
    redirect: eventsayewGGPTVIMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/product/[product_id]/events.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93rJxnN8DdoiMeta?.name ?? "product-product_id-free-media-media_id",
    path: _91media_id_93rJxnN8DdoiMeta?.path ?? "free-media/:media_id()",
    meta: _91media_id_93rJxnN8DdoiMeta || {},
    alias: _91media_id_93rJxnN8DdoiMeta?.alias || [],
    redirect: _91media_id_93rJxnN8DdoiMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/product/[product_id]/free-media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: free_45sampleueRoaT2dcaMeta?.name ?? "product-product_id-free-sample",
    path: free_45sampleueRoaT2dcaMeta?.path ?? "free-sample",
    meta: free_45sampleueRoaT2dcaMeta || {},
    alias: free_45sampleueRoaT2dcaMeta?.alias || [],
    redirect: free_45sampleueRoaT2dcaMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/product/[product_id]/free-sample.vue").then(m => m.default || m)
  },
  {
    name: included_45productsiu4eAYvTloMeta?.name ?? "product-product_id-included-products",
    path: included_45productsiu4eAYvTloMeta?.path ?? "included-products",
    meta: included_45productsiu4eAYvTloMeta || {},
    alias: included_45productsiu4eAYvTloMeta?.alias || [],
    redirect: included_45productsiu4eAYvTloMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/product/[product_id]/included-products.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93ByVGmLWM5hMeta?.name ?? "product-product_id-lessons-lesson_id-play-media_id",
    path: _91media_id_93ByVGmLWM5hMeta?.path ?? "lessons/:lesson_id()/play/:media_id()",
    meta: _91media_id_93ByVGmLWM5hMeta || {},
    alias: _91media_id_93ByVGmLWM5hMeta?.alias || [],
    redirect: _91media_id_93ByVGmLWM5hMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/play/[media_id].vue").then(m => m.default || m)
  },
  {
    name: viewvJLYK8IIJaMeta?.name ?? "product-product_id-lessons-lesson_id-view",
    path: viewvJLYK8IIJaMeta?.path ?? "lessons/:lesson_id()/view",
    meta: viewvJLYK8IIJaMeta || {},
    alias: viewvJLYK8IIJaMeta?.alias || [],
    redirect: viewvJLYK8IIJaMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/product/[product_id]/lessons/[lesson_id]/view.vue").then(m => m.default || m)
  },
  {
    name: indexX96fDBmnsqMeta?.name ?? "product-product_id-lessons",
    path: indexX96fDBmnsqMeta?.path ?? "lessons",
    meta: indexX96fDBmnsqMeta || {},
    alias: indexX96fDBmnsqMeta?.alias || [],
    redirect: indexX96fDBmnsqMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/product/[product_id]/lessons/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93FZe6bmOcdzMeta?.name ?? "product-product_id-media-media_id",
    path: _91media_id_93FZe6bmOcdzMeta?.path ?? "media/:media_id()",
    meta: _91media_id_93FZe6bmOcdzMeta || {},
    alias: _91media_id_93FZe6bmOcdzMeta?.alias || [],
    redirect: _91media_id_93FZe6bmOcdzMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/product/[product_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: index2ByNFgwVZvMeta?.name ?? "product-product_id-playlists-playlist_id",
    path: index2ByNFgwVZvMeta?.path ?? "playlists/:playlist_id()",
    meta: index2ByNFgwVZvMeta || {},
    alias: index2ByNFgwVZvMeta?.alias || [],
    redirect: index2ByNFgwVZvMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91media_id_93b4bFK6ZQiTMeta?.name ?? "product-product_id-playlists-playlist_id-media-media_id",
    path: _91media_id_93b4bFK6ZQiTMeta?.path ?? "playlists/:playlist_id()/media/:media_id()",
    meta: _91media_id_93b4bFK6ZQiTMeta || {},
    alias: _91media_id_93b4bFK6ZQiTMeta?.alias || [],
    redirect: _91media_id_93b4bFK6ZQiTMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/product/[product_id]/playlists/[playlist_id]/media/[media_id].vue").then(m => m.default || m)
  },
  {
    name: indexn7e2FabgbvMeta?.name ?? "product-product_id-playlists",
    path: indexn7e2FabgbvMeta?.path ?? "playlists",
    meta: indexn7e2FabgbvMeta || {},
    alias: indexn7e2FabgbvMeta?.alias || [],
    redirect: indexn7e2FabgbvMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/product/[product_id]/playlists/index.vue").then(m => m.default || m)
  },
  {
    name: private_45sessionsc13VWdfV1BMeta?.name ?? "product-product_id-private-sessions",
    path: private_45sessionsc13VWdfV1BMeta?.path ?? "private-sessions",
    meta: private_45sessionsc13VWdfV1BMeta || {},
    alias: private_45sessionsc13VWdfV1BMeta?.alias || [],
    redirect: private_45sessionsc13VWdfV1BMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/product/[product_id]/private-sessions.vue").then(m => m.default || m)
  },
  {
    name: recordingsTS4eJUqgTbMeta?.name ?? "product-product_id-recordings",
    path: recordingsTS4eJUqgTbMeta?.path ?? "recordings",
    meta: recordingsTS4eJUqgTbMeta || {},
    alias: recordingsTS4eJUqgTbMeta?.alias || [],
    redirect: recordingsTS4eJUqgTbMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/product/[product_id]/recordings.vue").then(m => m.default || m)
  },
  {
    name: reviewsDbZZkljlvZMeta?.name ?? "product-product_id-reviews",
    path: reviewsDbZZkljlvZMeta?.path ?? "reviews",
    meta: reviewsDbZZkljlvZMeta || {},
    alias: reviewsDbZZkljlvZMeta?.alias || [],
    redirect: reviewsDbZZkljlvZMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/product/[product_id]/reviews.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexsJrPy0WBebMeta?.name ?? "product",
    path: indexsJrPy0WBebMeta?.path ?? "/product",
    meta: indexsJrPy0WBebMeta || {},
    alias: indexsJrPy0WBebMeta?.alias || [],
    redirect: indexsJrPy0WBebMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/product/index.vue").then(m => m.default || m)
  },
  {
    name: indexxxL0vhgerhMeta?.name ?? "profile",
    path: indexxxL0vhgerhMeta?.path ?? "/profile",
    meta: indexxxL0vhgerhMeta || {},
    alias: indexxxL0vhgerhMeta?.alias || [],
    redirect: indexxxL0vhgerhMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/profile/index.vue").then(m => m.default || m)
  },
  {
    name: purchasesTq4XDWPhKCMeta?.name ?? "profile-purchases",
    path: purchasesTq4XDWPhKCMeta?.path ?? "/profile/purchases",
    meta: purchasesTq4XDWPhKCMeta || {},
    alias: purchasesTq4XDWPhKCMeta?.alias || [],
    redirect: purchasesTq4XDWPhKCMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/profile/purchases.vue").then(m => m.default || m)
  },
  {
    name: redemptionsAMptQrGcUEMeta?.name ?? "profile-redemptions",
    path: redemptionsAMptQrGcUEMeta?.path ?? "/profile/redemptions",
    meta: redemptionsAMptQrGcUEMeta || {},
    alias: redemptionsAMptQrGcUEMeta?.alias || [],
    redirect: redemptionsAMptQrGcUEMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/profile/redemptions.vue").then(m => m.default || m)
  },
  {
    name: _91quiz_id_93Ok1U4nfTLSMeta?.name ?? "quiz-quiz_id",
    path: _91quiz_id_93Ok1U4nfTLSMeta?.path ?? "/quiz/:quiz_id()",
    meta: _91quiz_id_93Ok1U4nfTLSMeta || {},
    alias: _91quiz_id_93Ok1U4nfTLSMeta?.alias || [],
    redirect: _91quiz_id_93Ok1U4nfTLSMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/quiz/[quiz_id].vue").then(m => m.default || m)
  },
  {
    name: _91token_93ZIxr7yh9T7Meta?.name ?? "reset-password-uid-token",
    path: _91token_93ZIxr7yh9T7Meta?.path ?? "/reset-password/:uid()/:token()",
    meta: _91token_93ZIxr7yh9T7Meta || {},
    alias: _91token_93ZIxr7yh9T7Meta?.alias || [],
    redirect: _91token_93ZIxr7yh9T7Meta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/reset-password/[uid]/[token].vue").then(m => m.default || m)
  },
  {
    name: resetp9Jfo31foeMeta?.name ?? "reset",
    path: resetp9Jfo31foeMeta?.path ?? "/reset",
    meta: resetp9Jfo31foeMeta || {},
    alias: resetp9Jfo31foeMeta?.alias || [],
    redirect: resetp9Jfo31foeMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/reset.vue").then(m => m.default || m)
  },
  {
    name: shopGyDZ1Rvn0nMeta?.name ?? "shop",
    path: shopGyDZ1Rvn0nMeta?.path ?? "/shop",
    meta: shopGyDZ1Rvn0nMeta || {},
    alias: shopGyDZ1Rvn0nMeta?.alias || [],
    redirect: shopGyDZ1Rvn0nMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/shop.vue").then(m => m.default || m)
  },
  {
    name: signupNZMMIIOocMMeta?.name ?? "signup",
    path: signupNZMMIIOocMMeta?.path ?? "/signup",
    meta: signupNZMMIIOocMMeta || {},
    alias: signupNZMMIIOocMMeta?.alias || [],
    redirect: signupNZMMIIOocMMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/signup.vue").then(m => m.default || m)
  },
  {
    name: liveAnE28KXQkvMeta?.name ?? "streaming-event_id-live",
    path: liveAnE28KXQkvMeta?.path ?? "/streaming/:event_id()/live",
    meta: liveAnE28KXQkvMeta || {},
    alias: liveAnE28KXQkvMeta?.alias || [],
    redirect: liveAnE28KXQkvMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/streaming/[event_id]/live.vue").then(m => m.default || m)
  },
  {
    name: tipsXq0M6uoTrpMeta?.name ?? "tips",
    path: tipsXq0M6uoTrpMeta?.path ?? "/tips",
    meta: tipsXq0M6uoTrpMeta || {},
    alias: tipsXq0M6uoTrpMeta?.alias || [],
    redirect: tipsXq0M6uoTrpMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/tips.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93PcTEv4dZhwMeta?.name ?? "waiver-uuid",
    path: _91uuid_93PcTEv4dZhwMeta?.path ?? "/waiver/:uuid()",
    meta: _91uuid_93PcTEv4dZhwMeta || {},
    alias: _91uuid_93PcTEv4dZhwMeta?.alias || [],
    redirect: _91uuid_93PcTEv4dZhwMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/waiver/[uuid].vue").then(m => m.default || m)
  },
  {
    name: compactqSp3RKcAnEMeta?.name ?? "widgets-calendars-compact",
    path: compactqSp3RKcAnEMeta?.path ?? "/widgets/calendars/compact",
    meta: compactqSp3RKcAnEMeta || {},
    alias: compactqSp3RKcAnEMeta?.alias || [],
    redirect: compactqSp3RKcAnEMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/widgets/calendars/compact.vue").then(m => m.default || m)
  },
  {
    name: fulliOI3YsDzzrMeta?.name ?? "widgets-calendars-full",
    path: fulliOI3YsDzzrMeta?.path ?? "/widgets/calendars/full",
    meta: fulliOI3YsDzzrMeta || {},
    alias: fulliOI3YsDzzrMeta?.alias || [],
    redirect: fulliOI3YsDzzrMeta?.redirect,
    component: () => import("/tmp/build_213e7411/pages/[studio_url]/widgets/calendars/full.vue").then(m => m.default || m)
  }
]